jQuery(document).ready(function($) {
  // Header hider
  var $header = $(".header");
  var lastScrollTop = 0;
  var delta = 5;
  var didScroll;
  var navbarHeight = $header.outerHeight();

  function hasScrolled() {
    var st = $(this).scrollTop();

    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }

    if (st > lastScrollTop && st > navbarHeight) {
      $header.removeClass("nav-down").addClass("nav-up");
    } else {
      if (st + $(window).height() < $(document).height()) {
        $header.removeClass("nav-up").addClass("nav-down");
      }
    }

    lastScrollTop = st;
  }

  function hideHeader() {
    $(window).scroll(function(event) {
      didScroll = true;
    });

    setInterval(function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);
  }

  hideHeader();

  // Home
  var $readMore = $(".video-bg__read-more").find("a");
  var $html = $("html");

  if ($readMore.length) {
    $readMore.on("click", function(e) {
      var aanbodTop = $(".block--trainings").offset().top;

      e.preventDefault();

      $html.stop().animate({ scrollTop: aanbodTop }, 700, "swing");
    });
  }

  var $clients = $(".slick-clients");

  $clients.slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  var $testimonials = $(".slick-testimonials");

  $testimonials.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false
  });

  // Menu
  var $parent = $(".menu-item-has-children").find(".nav-main-link");
  var $submenu = $(".sub-menu");

  $parent.on("click", function(e) {
    e.preventDefault();

    var $this = $(this).next(".sub-menu");

    if ($this.hasClass("open")) {
      $this.removeClass("open");
    } else {
      $submenu.removeClass("open");
      $this.addClass("open");
    }
  });

  var $html = $("html");

  $html.on("click touchend", function(e) {
    if (
      !$(e.target).closest(".sub-menu").length &&
      !$(e.target).closest(".menu-item-has-children").length
    ) {
      $(".sub-menu").removeClass("open");
    }

    if (!$(e.target).closest(".header").length) {
      if ($header.hasClass("open")) {
        $header.removeClass("open");
      }
    }
  });

  var $menuButton = $(".menu-button");

  $menuButton.on("click touchend", function(e) {
    e.preventDefault();

    $header.toggleClass("open");
  });
});
